@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "TirtoWriter";
  src: url("font/TirtoWritterRegular-Eajrl.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.StepButton-d2-0-2-8.active {
  background-color: #5082c8 !important;
}

.StepButton-d6-0-2-12.completed {
  background-color: grey !important;
}
.StepButton-d6-0-2-12.active {
  background-color: #5082c8 !important;
}
.StepButton-d2-0-2-8.completed {
  background-color: grey !important;
}
.StepButton-d4-0-2-14.active{
  background-color: #5082c8 !important;

}
.StepButton-d4-0-2-14.completed{
  background-color: grey !important;
}
.StepButton-d10-0-2-22.active {
  background-color: #5082c8 !important;
}
.StepButton-d0-0-2-6.active{
  background-color: #5082c8;
}
.StepButton-d0-0-2-6.completed{
  background-color: grey !important;
}
.StepButton-d10-0-2-22.completed {
  background-color: grey !important;
}
.font {
  font-family: "Meringue";
}
.font-jella-demo {
  font-family: "TirtoWriter";
}
/* ImageWithParagraph.css */
.custom-box-shadow {
  box-shadow: 0px 8px 12px 0px #00000024;
}
.my-custom-cookie-consent {
  height: 60px; /* Adjust the height as needed */
}
@media (max-width: 414px) {
.my-custom-cookie-consent{
margin-bottom: 0px;
padding-bottom:120px;
}
}